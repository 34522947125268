:root {
  --primary-color: #4a90e2;
  --secondary-color: #50b3a2;
  --dark-color: #333;
  --light-color: #f4f4f4;
  --font-stack: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body, html {
  height: 100%;
  font-family: var(--font-stack);
}

.header {
  background: var(--primary-color);
  color: var(--light-color);
  padding: 1rem 0;
}

.container {
  max-width: 1100px;
  margin: auto;
}

.navbar a {
  color: var(--light-color);
  text-decoration: none;
  padding: 0.5rem;
  margin: 0 0.5rem;
}

.navbar a.button {
  background: var(--secondary-color);
  border-radius: 5px;
}

.navbar a.button-outline {
  background: transparent;
  border: 1px solid var(--light-color);
}

.hero {
  background: var(--light-color);
  color: var(--dark-color);
  padding: 2rem 0;
  text-align: center;
}

.hero h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero p {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.button {
  display: inline-block;
  background: var(--primary-color);
  color: var(--light-color);
  padding: 0.75rem 1.5rem;
  border-radius: 5px;
  transition: background 0.3s ease;
}

.button:hover {
  background: var(--secondary-color);
}

.footer {
  background: var(--dark-color);
  color: var(--light-color);
  text-align: center;
  padding: 1rem 0;
  position: relative;
  bottom: 0;
  width: 100%;
}

@media (max-width: 768px) {
  .navbar {
    flex-direction: column;
  }

  .navbar a {
    padding: 1rem;
  }
  .hero {
    padding: 4rem 0;
  }
  .hero h2 {
    font-size: 2rem;
  }
  .hero p {
    font-size: 1rem;
  }
}

.footer {
  
  flex-shrink: 0;
}

